@import "variables";
@import "mixins";

.bt {
  @include button-blue;

  width: max-content;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  color: $text-color-dark;
  font-weight: 500;

  .content {
    display: flex;
    align-items: center;
    //white-space: nowrap;
    transition: opacity 300ms;
  }

  &.full {
    width: 100%;
  }
  &.loading {
    pointer-events: none;
    opacity: 0.8;

    .content {
      opacity: 0;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
  &.shadow {
    box-shadow: $shadow;
  }
  &.inline {
    display: inline-flex;
  }

  &.white {
    @include button-white;
  }
  &.black {
    @include button-black;
  }
  &.gray {
    @include button-gray;
  }
  &.green {
    @include button-green;
  }
  &.red {
    @include button-red;
  }
  &.yellow {
    @include button-yellow;
  }
  &.purple {
    @include button-purple;
  }
  &.transparent {
    background: transparent !important;
    border: 1px solid transparent !important;
  }
}
