.skelet {
  position: relative;
  line-height: 1;
  background-color: #e8ebef !important;
  background-image: linear-gradient(90deg, #e8ebef, #f6f7f9, #e8ebef) !important;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: 100%;
  animation: wheeeee 1.2s ease-in-out infinite;
  min-height: 12px;
  flex-shrink: 0;

  &.invert {
    background-color: white !important;
    background-image: linear-gradient(90deg, white, #fafbfd, white) !important;
  }
}

@keyframes wheeeee {
  0% { background-position: calc(200px + 100%) 0; }
  100% { background-position: -200px 0; }
}