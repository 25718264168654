@import "variables";
@import "mixins";

.carousel {
  position: relative;

  .carouselButton {
    @include button-blue;

    position: absolute;
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    z-index: 10;
    box-shadow: $shadow;

    &.carouselButtonPrevious {
      left: -25px;

      svg {
        position: relative;
        right: 1px;
      }
    }
    &.carouselButtonNext {
      right: -25px;

      svg {
        position: relative;
        left: 1px;
      }
    }

    svg {
      flex-shrink: 0;
    }

    @media (max-width: 1250px) {
      display: none;
    }
  }

  .carouselContent {
    display: flex;
    overflow: hidden;
    padding: 24px 0;
    margin: -24px 0;

    @media (max-width: 1250px) {
      scroll-snap-type: x mandatory;
      padding: 24px 18px;
      margin: -24px -18px;
    }
    @media (max-width: 992px) {
      overflow: auto;
      padding: 12px;
      margin: -12px;
    }

    .carouselItem {
      color: inherit;
      display: flex;
      flex-direction: column;
      border-radius: 18px;
      overflow: hidden;
      background-color: white;
      box-sizing: border-box;
      border: 1px solid #e8ebef;
      transition: all 300ms;
      flex-shrink: 0;
      width: 270px;
      transform: translate3d(0, 0, 0);
      scroll-snap-align: center;

      &:not(:first-child) {
        margin-left: 12px;
      }

      &:hover {
        color: inherit;
        box-shadow: $shadow;
      }

      .carouselItemGallery {
        position: relative;
  
        .carouselItemCounter {
          position: absolute;
          right: 8px;
          top: 8px;
          z-index: 5;
          background-color: white;
          padding: 10px 8px;
          border-radius: 12px;
          font-family: $font-title;
          box-shadow: $shadow;
          font-size: 14px;
          line-height: 1;
        }
        .carouselItemButtons {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          overflow: hidden;
          border-radius: 16px;
  
          svg {
            filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .1));
          }
  
          .carouselItemButtonLeft {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 40px;
            z-index: 5;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms;
  
            &:active {
              transform: scale(0.85);
            }
          }
          .carouselItemButtonRight {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 40px;
            z-index: 5;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms;
  
            &:active {
              transform: scale(0.85);
            }
          }
        }
        .carouselItemPhotos {
          height: 220px;
          display: flex;
          overflow: hidden;
          scroll-snap-type: x mandatory;
          flex-shrink: 0;
          padding: 2px;
          box-sizing: border-box;
    
          .carouselItemPhoto {
            position: relative;
            background-color: #e8ebef;
            color: $text-color-dark;
            border-radius: 16px;
            width: 100%;
            overflow: hidden;
            scroll-snap-align: center;
            flex-shrink: 0;
            transform: translate3d(0, 0, 0);
          
            &:not(:first-child) {
              margin-left: 8px;
            }
          }
          .carouselItemPhotoHash {
            z-index: 1;
            transition: opacity 500ms;
          }
          .carouselItemPhotoMore {
            display: flex;
  
            .carouselItemPhotoMoreText {
              font-size: 38px;
              font-family: $font-title;
              font-weight: 500;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              height: 100%;
        
              span {
                display: block;
                font-family: $font-text;
                font-size: 16px;
                margin-top: 4px;
                color: $text-color;
              }
            }
          }

          @media (max-width: 992px) {
            overflow: auto hidden;
          }
        }
      }
      .carouselItemLabels {
        position: absolute;
        left: 8px;
        top: 8px;
        z-index: 5;
    
        .carouselItemLabelsItem {
          padding: 10px 8px;
          z-index: 4;
          border-radius: 12px;
          font-size: 14px;
          width: max-content;
          line-height: 1;
    
          &:not(:first-child) {
            margin-top: 4px;
          }
      
          &.yellow {
            background-color: #ffde5a;
            box-shadow: 0 0 12px rgba(255, 222, 90, 0.7);
          }
          &.red {
            background-color: #E8505B;
            box-shadow: 0 0 12px rgb(232, 108, 108, 0.7);
            color: white;
          }
        }
      }
      .carouselItemContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 8px 18px 0 18px;
        box-sizing: border-box;

        .carouselItemAddress {
          display: -webkit-box;
          //white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 6px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          min-height: 2.4em;
          line-height: 1.2em;
        }
        .carouselItemPrice {
          display: flex;
          font-family: $font-title;
          font-size: 24px;
          font-weight: 600;
          white-space: nowrap;
          justify-content: space-between;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
            flex-shrink: 0;

            &.green {
              color: #90e184;
            }
            &.red {
              color: #ff8c8c;
            }
          }

          .carouselItemFavorites {
            outline: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            padding: 0;
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 12px;
            margin-left: 8px;
            flex-shrink: 0;
            color: inherit;
            transition: all 200ms;
    
            svg {
              transition: fill 200ms;
            }
    
            &:hover {
              color: #E8505B;
            }
            &:active {
              transform: scale(0.9);
            }
    
            &.active {
              color: #E8505B;
              background-color: rgba(232, 108, 108, 0.15);
    
              svg {
                fill: #E8505B;
              }
            }
          }
        }
        .carouselItemOldPrice {
          text-decoration: line-through;
          color: $text-color-light;
        }
        .carouselItemInfo {
          display: flex;
          justify-content: space-evenly;
          padding: 18px 8px;
          color: $text-color;
          margin: auto 0;
  
          .carouselItemProperty {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            font-weight: 500;
  
            div {
              margin-top: 2px;
              white-space: nowrap;
            }
          }
        }
        .carouselItemButton {
          @include button-blue;
  
          padding: 12px 24px;
          width: 100%;
          text-align: center;
          margin-top: auto;
        }
      }
      .carouselItemActions {
        display: flex;
        margin: 0 12px 12px 12px;
  
        
      }

      @media (max-width: 992px) {
        min-width: 300px;
        max-width: 300px;
        width: 100%;
        flex-shrink: 0;
        scroll-snap-align: center;
      }
      @media (max-width: 420px) {
        max-width: calc(100% - 48px);
      }
    }
    .carouselItemMore {
      @include button-blue;
  
      width: 270px;
      scroll-snap-align: center;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      padding: 24px;
      box-sizing: border-box;
      text-align: center;
      flex-direction: column;
      box-shadow: $shadow;
      margin-left: 12px;
      flex-shrink: 0;

      span {
        font-size: 25px;
        margin: auto;
      }
    }
    .carouselItemDummy {
      flex-shrink: 0;
      border-right: 12px solid transparent;

      @media (min-width: 576px) {
        display: none;
      }
    }
  }
}