$main-color-dark: #43545B;
$main-color: #304e55;
$main-color-light: #E8505B;

$text-color-dark: #404040;
$text-color: #757575;
$text-color-light: #c0c0c0;

$background-color: #f9f9f9;

$background-button-hover:rgba(232, 80, 164, 0.1);
$background-button-active:rgba(232, 80, 164, 0.2);

$shadow:  0 0 1px rgba(0, 0, 0, 0.15),
          0 2px 6px rgba(68, 52, 61, 0.05),
          0 10px 20px rgba(68, 52, 61, 0.05);
$shadow-with-border:  0 0 1px rgba(0, 0, 0, 0.3),
                      0 2px 6px rgba(68, 52, 61, 0.06),
                      0 10px 20px rgba(52, 61, 68, 0.06);

$font-title: "TT Norms", sans-serif;
$font-text: "Gilroy", sans-serif;