@import "variables";

.title {
    font-size: 46px;
    font-weight: 800;
}

.body {
    font-size: 26px;
    font-weight: 600;
}

.site {
    font-size: 26px;
    font-weight: 600;
    color: $main-color-light
}